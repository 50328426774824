<template>
	<div id="product-details">
		<div class="container" v-if="!loading">
			<div class="product-display">
				<div class="row">
					<div class="col-xs col-md">
						<div class="img-wrap">
							<img v-if="product" :src="getImage" :alt="product.PckgName" />
						</div>
					</div>
				</div>
			</div>
			<div class="table-container">
				<div class="error" v-if="error">
					<span>{{ errorMsg }} </span>
				</div>
				<table class="table product-table">
					<thead>
						<tr>
							<th>Tour Option</th>
							<th>Transfer Option</th>
							<th>Tour Date</th>
							<th>Adult</th>
							<th>Child</th>
							<th>Infant</th>
							<th>Total</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(data, index) in product" :key="index" class="table-row"
							:class="[ { active: selectedProduct.id === data.PckgId } ]"
							@click="setCurrentProduct(data)" ref="selector">
							<td style="text-align: left">
								<span>{{ data.PckgSub }}</span>
							</td>
							<td>
								<b-form-select v-if="selectedProduct['id'] === data.PckgId"
									v-model="selectedProduct['transfer']" @change="resetValues()" :options="options[0]" required>
								</b-form-select>
							</td>
							<td>

								<b-form-datepicker v-if="(selectedProduct['id'] === data.PckgId) && selectedProduct['transfer']"
									v-model="selectedProduct['date']" button-only locale="en-US" placeholder=""
									class="form-control date" @change="resetValues"
									:date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" reset-button
									required>
								</b-form-datepicker>

							</td>
							<td>
								<b-form-select v-if="(selectedProduct['id'] === data.PckgId) && selectedProduct['transfer']"
									:options="setOptions" v-model="selectedProduct['adults']" required></b-form-select>
							</td>
							<td>
								<b-form-select v-if="(selectedProduct['id'] === data.PckgId) && selectedProduct['transfer']"
									:options="setOptions" v-model="selectedProduct['child']" required></b-form-select>
							</td>
							<td>
								<b-form-select v-if="(selectedProduct['id'] === data.PckgId) && selectedProduct['transfer']"
									:options="setOptions" v-model="selectedProduct['infant']" required></b-form-select>
							</td>
							<td>
								<span v-if="!selectedProduct.total" class="total">AED {{ data.PckgPriceAEDAD }}</span>
								<span v-else-if="(selectedProduct['id'] === data.PckgId) && selectedProduct.total"
									class="total">AED {{ selectedProduct.total }}</span>
							</td>
							<td>
								<button v-if="!error && selectedProduct['id'] === data.PckgId && selectedProduct['transfer']" class="addcart" @click.prevent="addtocart">Add To Cart</button>
							</td>
						</tr>
					</tbody>
				</table>

			</div>
			<div class="content" v-if="product">
				<div class="desc">
					<h4>Description</h4>
					<p>{{ product[0]['Description'] }}</p>
				</div>
				<div class="inclusions">
					<h4>Inclusions</h4>
					<p v-html="product[0]['Inclusions']"></p>
				</div>
				<div class="policies">
					<h4>Policies</h4>
					<p v-html="product[0]['Policies']"></p>
				</div>

			</div>
		</div>
	</div>
</template>
<script>

import _ from 'lodash'

export default {
	data: function () {
		return {
			loading: true,
			product: {},
			currentProduct: {},
			isActive: false,
			error: false,
			errorMsg: '',
			inclusions: '',
			selectedProduct: {
				adults: 0,
				child: 0,
				infant: 0,
				total: 0
			},
			options: [
				{
					0: "Without Transfer",
					1: "Private Transfer",
					2: "Sharing Transfer",
				}
			]
		};
	},

	async beforeRouteEnter(to, from, next) {
		next(async (vm) => {
			vm.getPackageData();
		});
	},

	computed: {

		setOptions: function () {
			let arr = []
			if (parseFloat(this.selectedProduct.transfer) == 1) {

				for (let i = 0; i <= 7; i++) {
					arr.push(i)
				}

				return arr
			}

			if (parseFloat(this.selectedProduct.transfer) == 0 || parseFloat(this.selectedProduct.transfer) == 2) {
				for (let i = 0; i <= 30; i++) {
					arr.push(i)
				}
				return arr
			}
		},

		getImage: function() {
			return `https://www.desertlinktours.com/api/product-images/${this.product[0].PckgName}.webp`
		}
	},

	watch: {

		"selectedProduct.date": function(val) {
			if(val) {
				this.selectedProduct.adults = 0
				this.selectedProduct.child = 0
				this.selectedProduct.infant = 0
				this.selectedProduct.total = 0
			}
		},

		"selectedProduct.adults": function (val) {
			let total = 0
			let sp = this.selectedProduct

			if (this.error) { this.error = false }

			if (this.selectedProduct && parseFloat(this.selectedProduct.transfer) == 1) {
				total = val + sp.child + sp.infant

				if (total > 7) {
					this.error = true;
					this.errorMsg = 'Total Number of Passengers Exceeded in Private Transfer. Select Sharing Transfer..'
				}

				switch (total) {
					case 1:
						sp.total = this.currentProduct.PTransfer1; break;
					case 2:
						sp.total = this.currentProduct.PTransfer2; break;
					case 3:
						sp.total = this.currentProduct.PTransfer3; break;
					case 4:
						sp.total = this.currentProduct.PTransfer4; break;
					case 5:
						sp.total = this.currentProduct.PTransfer5; break;
					case 6:
						sp.total = this.currentProduct.PTransfer6; break;
					case 7:
						sp.total = this.currentProduct.PTransfer7; break;
				}
			} else {
				sp.total = (val + sp.child + sp.infant) * this.currentProduct.STransferPrice
			}

			return val
		},

		"selectedProduct.child": function (val) {
			let total = 0
			let sp = this.selectedProduct

			if (this.selectedProduct && parseFloat(this.selectedProduct.transfer) == 1) {
				total = val + sp.adults + sp.infant

				if (total > 7) {
					this.error = true;
					this.errorMsg = 'Total Number of Passengers Exceeded in Private Transfer. Select Sharing Transfer..'
				}

				switch (total) {
					case 1:
						sp.total = this.currentProduct.PTransfer1; break;
					case 2:
						sp.total = this.currentProduct.PTransfer2; break;
					case 3:
						sp.total = this.currentProduct.PTransfer3; break;
					case 4:
						sp.total = this.currentProduct.PTransfer4; break;
					case 5:
						sp.total = this.currentProduct.PTransfer5; break;
					case 6:
						sp.total = this.currentProduct.PTransfer6; break;
					case 7:
						sp.total = this.currentProduct.PTransfer7; break;
				}
			} else {
				sp.total = (val + sp.adults + sp.infant) * this.currentProduct.STransferPrice
			}

			return val
		},

		"selectedProduct.infant": function (val) {
			let total = 0
			let sp = this.selectedProduct

			if (this.selectedProduct && parseFloat(this.selectedProduct.transfer) == 1) {
				total = val + sp.adults + sp.child

				if (total > 7) {
					this.error = true;
					this.errorMsg = 'Total Number of Passengers Exceeded in Private Transfer. Select Sharing Transfer..'
				}

				switch (total) {
					case 1:
						sp.total = this.currentProduct.PTransfer1; break;
					case 2:
						sp.total = this.currentProduct.PTransfer2; break;
					case 3:
						sp.total = this.currentProduct.PTransfer3; break;
					case 4:
						sp.total = this.currentProduct.PTransfer4; break;
					case 5:
						sp.total = this.currentProduct.PTransfer5; break;
					case 6:
						sp.total = this.currentProduct.PTransfer6; break;
					case 7:
						sp.total = this.currentProduct.PTransfer7; break;
				}
			} else {
				sp.total = (val + sp.adults + sp.child) * this.currentProduct.STransferPrice
			}

			return val
		}
	},

	methods: {

		getPackageData: async function () {
			let id = this.$route.params.id;
			await this.$store.dispatch("packages/getPackagesByParentId", id).then((response) => {
				this.product = response.data;
				this.loading = false;
			}).catch((error) => console.debug(error));
		},

		setCurrentProduct: function (data) {
			this.currentProduct = data;
			this.selectedProduct.id = data['PckgId'];

			if(this.currentProduct.PckgId == data.PckgId) { this.isActive = true }

			this.$forceUpdate()
		},

		resetValues: function () {
			this.selectedProduct.adults = 0
			this.selectedProduct.child = 0
			this.selectedProduct.infant = 0
			this.selectedProduct.total = 0
			this.selectedProduct.date = 0
		},

		addtocart: async function () {
			let vm = this

			if (_.isEmpty(this.selectedProduct) && _.isEmpty(this.selectedProduct.id)) {
				this.error = true
				return this.errorMsg = "Please Select a Package"
			}

			if (!this.selectedProduct.date) {
				this.error = true
				return this.errorMsg = "Please Select the Tour Date"
			}

			if (this.selectedProduct.adults === 0) {
				this.error = true
				return this.errorMsg = "Minimum 1 Adult is required!"
			}

			if (!this.error) {

				this.selectedProduct.name = this.currentProduct.PckgSub

				this.selectedProduct.img = this.currentProduct.PckgName + '.webp';

				let transfer = parseFloat(this.selectedProduct.transfer)

				if (transfer == 0 || transfer == 1 || transfer == 2) {
					this.selectedProduct.transfer = this.options[0][transfer]
				}

				await this.$store.dispatch("cart/saveCart", vm.selectedProduct).then((response) => {
					this.error = true
					this.errorMsg = "Package has been added to Cart"
					setTimeout(() => {
						this.$router.push('/packages')
					}, 2000)
				}).catch((error) => console.debug(error))
			}
		},

		selectProduct: function (data) {

			if (data.PckgId === this.selectedProduct.id) {
				return true
			}

		},

	}
}
</script>
<style lang="scss" scoped>
#product-details
{
	margin: 2rem;
	height: 100%;
	font-family: 'Open Sans', sans-serif;

	.product-display
	{
		display: flex;
		width: 100%;
		row-gap: 20px;
		flex-direction: column;

		.row
		{
			width: 100%;
		}

		.img-wrap
		{
			display: flex;
			justify-content: center;

			img
			{
				width: 30rem;
			}
		}
	}

	.error
	{
		background: #f44336;
		display: flex;
		justify-content: center;
		margin: 10px 0;
		color: #FFEBEE;
		padding: 10px 0;
		border-radius: 6px;
	}

	.total
	{
		color: #212121;
		font-weight: 700;
		white-space: nowrap;
	}

	.table-container
	{
		margin: 2rem 0;

		.product-table
		{
			background: white;
			padding: 1rem !important;
			border-collapse: collapse;

			.checkbox {
				.btn {
					background-color: unset;
					background-color: red;
				}
			}

			th
			{
				padding: 1rem;
				text-align: center;
				// background: rgb(255, 213, 79);
				background: #FFEB3B;
				white-space: nowrap;
				color: #424242;
			}

			tbody
			{

				.active {
					background: #212121;
					color: white;
					transition: all 100ms ease;

					.total {
						color: white;
					}

					&:hover {
						background: #212121!important;
						color: white!important;
					}
				}

				.table-row
				{
					text-align: center;
					vertical-align: center;
					cursor: pointer;

					.date {
						color: #212121;
						display: unset!important;
					}


					&:hover {
						background: #eaeaea;
						color: #212121;
						transition: all 300ms ease;
					}


				}


				.addcart
				{
					background: #f44336;
					width: 120px;
					height: 40px;
					outline: none;
					border: 1px solid transparent;
					border-radius: 4px;
					display: flex;
					justify-content: center;
					align-items: center;
					z-index: 3;
					color: white;
					transition: all 300ms ease-in-out;
				}

				.addcart:hover
				{
					background: #fff;
					border-radius: 0px;
					color: #212121;
					border: 1px solid #fff;
					transition: all 300ms ease-in-out;
				}
			}
		}

	}

	.content
	{
		padding: 1rem;
		margin: 20px 0;
		display:flex;
		row-gap: 20px;
		flex-direction: column;
		p
		{
			font-weight: 400;
			line-height: 2rem;
		}

		h4
		{
			text-transform: uppercase;
			font-weight: 600;
			position: relative;

			&:after
			{
				content: '';
				position: absolute;
				width: 20rem;
				height: 4px;
				bottom: 13px;
				left: 15%;
				background: #FFC107;
			}
		}
	}





}
</style>
